svg4everybody();

const APP = {

};
var bar_length = 300;
$(function() {
	console.log(`iBrush HTML Starter`);
	console.log(APP);

    mm_fullPage();
    backgroundMove();
    mm_sound();
    //mm_draw();
    mm_navigation();
    mm_popup();

    $('.js-slider-header').on('mouseenter mouseleave', function(){
        $(this).addClass('_slide-out-hover').delay(300).queue(function(next){
            $(this).removeClass('_slide-out-hover');
            next();
        });
    });

});

$(window).on('load', function(){

    progress_bar();

});
function mm_fullPage() {
    var fullPage = $('#_js-full-page');

	
	
    fullPage.fullpage({
        sectionSelector: '.mm_section',
        anchors: [
            'artist',
            'about',
			'contacts'
        ],
		afterRender: function () {
			if (document.location.hash == '#contacts') {
				$('.mm_section_about').addClass('block-animation');
				//setTimeout(function () {
					$('.mm_section_about').removeClass('anim-at_bottom').addClass('anim-at_top');
				//}, 4);
				
				setTimeout(function () {
					$('.mm_section_about').removeClass('block-animation');
				}, 4000);
			}
		},
		onLeave: function (index, nextIndex, direction) {
			//console.log('onLeave', index, nextIndex, direction);
			if (nextIndex != 1) {
				stopSliderTimer();
			} else {
				startSliderTimer();
			}
			
			if (direction == 'down') {
				if (index+1 != nextIndex) {
					for (var i = index+1; i<nextIndex; i++) {
						$('.mm_section').eq(i-1).addClass('block-animation').removeClass('anim-at_bottom').addClass('anim-at_top');
						$('.mm_section').removeClass('block-animation');
					}
				}
				$('.mm_section').eq(index-1).removeClass('anim-at_bottom').addClass('anim-at_top');
				$('.mm_section').eq(nextIndex-1).removeClass('anim-at_top').addClass('anim-at_bottom');

                if ( nextIndex > 1 ) {
                    //stop
                }

			}
			if (direction == 'up') {
				if (index-1 != nextIndex) {
					for (var i = nextIndex+1; i<index; i++) {
						$('.mm_section').eq(i-1).addClass('block-animation').removeClass('anim-at_top').addClass('anim-at_bottom');
						$('.mm_section').removeClass('block-animation');
					}
				}
				$('.mm_section').eq(nextIndex-1).removeClass('anim-at_bottom').addClass('anim-at_top');
				$('.mm_section').eq(index-1).removeClass('anim-at_top').addClass('anim-at_bottom');

                if ( nextIndex < 2 ) {
                    //start
                }

			}
		}
    });
}
var blockBgMove = false;
function backgroundMove() {

    var center = [document.body.clientWidth / 2, document.body.clientHeight / 2];
    var timer;
    $('body').on('mousemove', function (e) {
		if (blockBgMove) return false;
        //if (timer) clearTimeout(timer);

        // timer = setTimeout(function () {

        $('.mm_section-background__item._1 .mm_section-background__image img').css({ 'transform': 'translateX(-' + (50 - (e.originalEvent.clientX - center[0]) / center[0] * 0.01 * 100) + '%) translateY(-' + (50 - (e.originalEvent.clientY - center[1]) / center[1] * 0.01 * 100) + '%)' }); //, 300);
        $('.mm_section-background__item._2 .mm_section-background__image img').css({ 'transform': 'translateX(-' + (50 - (e.originalEvent.clientX - center[0]) / center[0] * 0.01732 * 100) + '%) translateY(-' + (50 - (e.originalEvent.clientY - center[1]) / center[1] * 0.01732 * 100) + '%)' }); //, 300);, 300);
        $('.mm_section-background__item._3 .mm_section-background__image img').css({ 'transform': 'translateX(-' + (50 - (e.originalEvent.clientX - center[0]) / center[0] * 0.03 * 100) + '%) translateY(-' + (50 - (e.originalEvent.clientY - center[1]) / center[1] * 0.03 * 100) + '%)' }); //, 300);, 300);
        //}, 200);
    });
}
function mm_sound() {
    var sound	        = document.getElementById('_main-audio'),
        volumeControl 	= $('._js-sound_control');

    volumeControl.on('click', function(){
        if ( !$(this).hasClass('_on') ) {
            $(this).addClass('_on').removeClass('_off');
            mm_enableVolume();
        } else {
            $(this).removeClass('_on').addClass('_off');
            mm_disableVolume();
        }
    });

    if (sound) {
        $(document).ready(function () {
            sound.volume = 1;
            if (!sound.paused) $('._js-sound_control').addClass('_on');
			else {
				$('._js-sound_control').addClass('_off');
				toggledecay();
			}
        });
		mm_draw();
    }
    function mm_enableVolume() {
        sound.muted = false;
        sound.volume = 1;
    }
    function mm_disableVolume() {
        sound.volume = 0;
        sound.muted = true;
    }
}


function move(amplitude, frequency, length, i, height) {
    var points = []; //[0,amplitude*2];
    var width = length;
    var x = 0,
        y;
    while (x++ <= width) {
        y = Math.sin(x * frequency + i);
        points.push([x, height + y * amplitude / 2].join(' '));
    }
    //points.push([length,amplitude*2].join(' '))
    //points.push([0,amplitude*2].join(' '));
    return points;
}
var svg = document.getElementById('_js-volume-svg');
var line = svg.querySelector('polyline');
var counter = 0;
var width = svg.getAttribute('width');
var height = svg.getAttribute('height') / 2;
var currentHeight = height;
var decay = false;
function mm_draw() {
    var c = ++counter / 20;
    if (decay && currentHeight > 0) currentHeight -= 0.25;
    if (!decay && currentHeight < height) currentHeight += 0.25;
    line.setAttribute('points', move(currentHeight, 0.48, width, c, height));

    requestAnimationFrame(mm_draw);
}

function toggledecay() {
    decay = !decay;
}
$(function () {
    var dataArray = [
        {
            index: 1,
            name: 'Biffy Clyro',
            headline: '19:00',
            popup: 'popup-biffy',
            image: '/assets/artist/Biffy.jpg',
            youtube: 'https://www.youtube.com/user/biffyclyro'
        },
        {
            index: 2,
            name: 'Wolf Alice',
            headline: '19:01',
            popup: 'popup-wolf',
            image: '/assets/artist/Wolf.jpg',
            youtube: 'https://www.youtube.com/user/WolfAliceVEVO',
            itunes: 'https://itunes.apple.com/ru/album/visions-of-a-life/id1252047326?app=itunes&ign-mpt=uo%3D4',
        },
        {
            index: 3,
            name: 'Miles Kane',
            headline: '19:03',
            popup: 'popup-miles',
            image: '/assets/artist/Miles.jpg',
            youtube: 'https://www.youtube.com/user/MilesKaneVEVO',
            itunes: 'https://itunes.apple.com/gb/artist/miles-kane/217975667%20'
        },
        {
            index: 4,
            name: 'Circa Waves',
            headline: '19:02',
            popup: 'popup-circa',
            image: '/assets/artist/Circa.jpg',
            youtube: 'https://www.youtube.com/user/CircaWavesVEVO',
            spotify: 'https://www.youtube.com/channel/UCPMi2f__yiQPG0GOh35Bj4w'
        },
        {
            index: 5,
            name: 'Sundara Karma',
            headline: '19:04',
            popup: 'popup-sundara',
            image: '/assets/artist/Sandra.jpg',
            youtube: 'https://www.youtube.com/channel/UCPMi2f__yiQPG0GOh35Bj4w',
            spotify: 'https://open.spotify.com/user/sundara.karma/playlist/0KECJtiyETsDPYs8YkcI6m',
        },
        {
            index: 6,
            name: 'Tom Grennan',
            headline: '19:05',
            popup: 'popup-tom',
            image: '/assets/artist/Tom.jpg',
            youtube: 'https://www.youtube.com/user/TomGrennanVEVO'
        }

    ];
	var currentArtist = 0;
	var totalArtists = dataArray.length;
	$('.js-total-artists').text(totalArtists);
	$('.js-current-artist').text(dataArray[currentArtist].index);
	$('.js-popup-container').children().hide().end().find('#'+dataArray[currentArtist].popup).show();
	
	if (dataArray[currentArtist].youtube) $('#_js-youtube-link').attr('href', dataArray[currentArtist].youtube).parent().show();
	else $('#_js-youtube-link').parent().hide();
	if (dataArray[currentArtist].itunes) $('#_js-itunes-link').attr('href', dataArray[currentArtist].itunes).parent().show();
	else $('#_js-itunes-link').parent().hide();
	if (dataArray[currentArtist].spotify) $('#_js-spotify-link').attr('href', dataArray[currentArtist].spotify).parent().show();
	else $('#_js-spotify-link').parent().hide();
	
	window.sliderTimer = false;
	
	var images = [];
	/* прелоад картинок */
	for (var i in dataArray) {
		var img = new Image();
		img.src = dataArray[i].image;
		images.push(img);
	}
	
	$('.js-artist-left').on('click',function () {
		if (blockBgMove) return false;
		stopSliderTimer();
		blockBgMove = true;
		
		currentArtist--;
		if (currentArtist < 0) currentArtist = totalArtists-1;
		
		moveArtist();
	});
	
	function moveArtist() {
		$('.mm_section-background__image img').addClass('state-old').removeAttr('style');
		$('.mm_section-background__image').append('<img src="'+dataArray[currentArtist].image+'" class="state-new" />');

		//$('#_js-youtube-link').attr('href', dataArray[currentArtist].youtube);

        if (dataArray[currentArtist].youtube) $('#_js-youtube-link').attr('href', dataArray[currentArtist].youtube).parent().show();
		else $('#_js-youtube-link').parent().hide();
		if (dataArray[currentArtist].itunes) $('#_js-itunes-link').attr('href', dataArray[currentArtist].itunes).parent().show();
		else $('#_js-itunes-link').parent().hide();
		if (dataArray[currentArtist].spotify) $('#_js-spotify-link').attr('href', dataArray[currentArtist].spotify).parent().show();
		else $('#_js-spotify-link').parent().hide();

		setTimeout(function () {
			$('.mm_section-background__image img').addClass('status-move-left');
			$('.js-slider-header').addClass('slide-out');
		}, 40);
		
		setTimeout(function () {
			var html = dataArray[currentArtist].name.split(' ').map(function (item) { return '<span>'+item+'</span>'; });
			$('.js-slider-header').removeClass('slide-out').html( html.join(' ') );
		}, 550);
		
		setTimeout(function () {
			$('.mm_section-background__image img.state-old').remove();
			$('.mm_section-background__image img').removeClass();

			$('.js-total-artists').text(totalArtists);
			//$('.js-current-artist').text(dataArray[currentArtist].index);

            setTimeout(function () {
                $('.mm_slider-controls__counter-no._current').addClass('slide-out');
            }, 40);

            setTimeout(function () {
                $('.mm_slider-controls__counter-no._current').removeClass('slide-out');
                $('.js-current-artist').text(dataArray[currentArtist].index);
            }, 100);
	
			blockBgMove = false;
		}, 1200);
		
		$('.js-popup-container').children().hide().end().find('#'+dataArray[currentArtist].popup).show();
	}
	
	$('.js-artist-right').on('click',function () {
		if (blockBgMove) return false;
		stopSliderTimer();
		blockBgMove = true;
		
		currentArtist++;
		if (currentArtist > totalArtists-1) currentArtist = 0;
		
		moveArtist();
		
		/* $('.mm_section-background__image img').addClass('state-old').removeAttr('style');
		$('.mm_section-background__image').append('<img src="'+dataArray[currentArtist].image+'" class="state-new-right" />');
		
		setTimeout(function () {
			$('.mm_section-background__image img').addClass('status-move-right');
		}, 10);
		
		setTimeout(function () {
			$('.mm_section-background__image img.state-old').remove();
			$('.mm_section-background__image img').removeClass();
			
			$('.js-total-artists').text(totalArtists);
			$('.js-current-artist').text(dataArray[currentArtist].index);
			
			blockBgMove = false;
		}, 1200); */
	});
	
	startSliderTimer();
});

function startSliderTimer() {
	window.sliderTimer = setTimeout(function () { 
		$('.js-artist-right').click(); 
		startSliderTimer();
	}, 5000);
}

function stopSliderTimer() {
	if (window.sliderTimer) clearTimeout(window.sliderTimer);
}
function mm_navigation() {

    var burger      = $('#_nav-burger'),
        navigation  = $('#_navigation'),
        navLink     = $('._js-nav-link');

    burger.on('click', function(){

        if ( !$(this).hasClass('_active') ) {
            $(this).addClass('_active');
            navigation.addClass('_expanded');
            //disable vertical scrolling
            $.fn.fullpage.setAllowScrolling(false);
        } else {
            $(this).removeClass('_active');
            navigation.removeClass('_expanded');
            //enable vertical scrolling
            $.fn.fullpage.setAllowScrolling(true);
        }

    });

    navLink.on('click', function(){
        // navLink.removeClass('_active');
        /*if ( !$(this).hasClass('_active') ) {
            $(this).addClass('_active');

        }*/
        burger.removeClass('_active');
        navigation.removeClass('_expanded');
        //enable vertical scrolling
        $.fn.fullpage.setAllowScrolling(true);
    });

}
function mm_popup() {

    var show    = $('._js-show-popup'),
        hide    = $('._js-hide-popup'),
        wrapper = $('.mm_website-wrapper');

    show.on('click', function () {
        if (blockBgMove) return false;
        blockBgMove = true;
		stopSliderTimer();
		
        if (!$(this).hasClass('_active')) {
            $(this).addClass('_active');
            wrapper.addClass('_popup-shown');
            //disable vertical scrolling
            $.fn.fullpage.setAllowScrolling(false);
        } else {
            $(this).removeClass('_active');
            wrapper.removeClass('_popup-shown');
            //enable vertical scrolling
            $.fn.fullpage.setAllowScrolling(true);
        }
        setTimeout(function () { blockBgMove = false; }, 1500);
    });
    hide.on('click', function () {
        if (blockBgMove) return false;
        blockBgMove = true;
		
        show.removeClass('_active');
        wrapper.removeClass('_popup-shown');
        //enable vertical scrolling
        $.fn.fullpage.setAllowScrolling(true);
        setTimeout(function () { blockBgMove = false; }, 1500);
		startSliderTimer();
    });

}
function mm_autoPlay() {
   /* var autoPlayInterval = null,
        stopTrigger      = $('._js-show-popup'),
        startTrigger     = $('._js-hide-popup'),
        arrows           = $('.js-artist-left, .js-artist-right'),
        timer            = 4000;

    function autoPlay() {
        $('.js-artist-right').trigger('click');
    }
    stopTrigger.on('click', function(){
        clearInterval(autoPlayInterval);
    });
    startTrigger.on('click', function(){
        autoPlayInterval = setInterval(autoPlay, timer);
    });

    autoPlayInterval = setInterval(autoPlay, timer);*/
}

var time;

function autoPlay() {
    $('.js-artist-right').trigger('click');

    time = setTimeout(function (){
        $('.js-artist-right').trigger('click');
    });
}
clearTimeout(time);


time = setTimeout(function (){
    $('.js-artist-right').trigger('click');
});



var preloader       = document.getElementById('_preloader-wrapper');
var websiteWrapper  = document.getElementById('_website-wrapper');
var preloaderLine   = document.getElementById('_loading-line');
var more = 0;
var bar_length = 280;
var wait = 7000;
var doneyet = 0;
function progress_bar()
{

    /* the following document element retreives the number of
    images on the HTML document */
    var image_count = document.getElementsByTagName("img").length;

    /* the following variable gets an array of all the images
    in the document */
    var image_array = document.getElementsByTagName("img");

    /* Each part of the progress bar will be bar_length divided by
    image_count rounded. For example: If there are 5 images and
    the total bar_length is 300 then each bar_part will be 60 */
    var bar_part = Math.round(bar_length / image_count);

    /* To display the correct percentage, bar_perc is 100 divided
    by the number of images on the page rounded */
    var bar_perc = Math.round(100 / image_count);

    var new_width = 0; // Will become new width of progress bar
    var j = 0;  // count how many images are complete
    var percent = 0; // Add up the percentage

    for (var i = 0; i < image_count; i++)
    {
        /* The javascript variable 'complete' when used on an
        image element retrieves whether an image is done
        loading or not.  It returns true or flase */
        if (image_array[i].complete)
        {
            percent = percent + bar_perc;
            new_width = new_width + bar_part;
            j++;
        }
    }

    // If the new_width is not growing because an image is still
    // loading then we want to make the bar go up 1% every 1 second
    // as long as it has not reached the next bar_part
    if (new_width <= parseFloat(preloaderLine.style.width)
        && new_width < (j*bar_part + bar_part))
    {
        more = more + .50;
        new_width = new_width + Math.round(more);
        percent = percent + Math.round(more);
    }
    else
        more = 0;  // reset more if we loaded next image

    // The is so the percentage can never go past 100
    if (percent > 100) { percent = 100; } // Bug fix from Martijn189 on 4/13/10

    // Write the new percent in the progress bar window
    //document.getElementById('percent').innerHTML = percent + '%';
    // Make the width of the bar wider so that it matches the percent
    preloaderLine.style.width = new_width + 'px';

    checkstate(); // need for safari
    //document.getElementById('bar').innerHTML = document.readyState;

    /* If all the images have not loaded then call this
    function again in 200ms.  There must be at least one
    image in the document or the progress bar window
    never closes */
    if ( (j < image_count || j == 0 || doneyet == 0) ) {
        setTimeout('progress_bar();', 200);
    }
    else {
        setTimeout(function(){
            preloader.classList.add("_loaded");
            websiteWrapper.removeAttribute("style");
        },500);
    } // if done then close the progress bar pop-up window

        //setTimeout('close_bar();', 500);
} // end function progress_bar()
function checkstate()
{
    // Besides IE, Safari also can use document.readyState
    // but Safari does not support onreadystatechange, so
    // we will keep calling this function with progress_bar().

    // Check to see if document is not "complete" but
    // is loaded enough to be "interactive"
    if (document.readyState=="complete" ||
        document.readyState=="complete")
        doneyet = 1;

} // end function checkstate()
function saydone()
{
    doneyet = 1;
}  // end function saydone()

// for other browsers that don't have DOM complete variables
setTimeout('saydone();', wait);
