$(function () {
    var dataArray = [
        {
            index: 1,
            name: 'Biffy Clyro',
            headline: '19:00',
            popup: 'popup-biffy',
            image: '/assets/artist/Biffy.jpg',
            youtube: 'https://www.youtube.com/user/biffyclyro'
        },
        {
            index: 2,
            name: 'Wolf Alice',
            headline: '19:01',
            popup: 'popup-wolf',
            image: '/assets/artist/Wolf.jpg',
            youtube: 'https://www.youtube.com/user/WolfAliceVEVO',
            itunes: 'https://itunes.apple.com/ru/album/visions-of-a-life/id1252047326?app=itunes&ign-mpt=uo%3D4',
        },
        {
            index: 3,
            name: 'Miles Kane',
            headline: '19:03',
            popup: 'popup-miles',
            image: '/assets/artist/Miles.jpg',
            youtube: 'https://www.youtube.com/user/MilesKaneVEVO',
            itunes: 'https://itunes.apple.com/gb/artist/miles-kane/217975667%20'
        },
        {
            index: 4,
            name: 'Circa Waves',
            headline: '19:02',
            popup: 'popup-circa',
            image: '/assets/artist/Circa.jpg',
            youtube: 'https://www.youtube.com/user/CircaWavesVEVO',
            spotify: 'https://www.youtube.com/channel/UCPMi2f__yiQPG0GOh35Bj4w'
        },
        {
            index: 5,
            name: 'Sundara Karma',
            headline: '19:04',
            popup: 'popup-sundara',
            image: '/assets/artist/Sandra.jpg',
            youtube: 'https://www.youtube.com/channel/UCPMi2f__yiQPG0GOh35Bj4w',
            spotify: 'https://open.spotify.com/user/sundara.karma/playlist/0KECJtiyETsDPYs8YkcI6m',
        },
        {
            index: 6,
            name: 'Tom Grennan',
            headline: '19:05',
            popup: 'popup-tom',
            image: '/assets/artist/Tom.jpg',
            youtube: 'https://www.youtube.com/user/TomGrennanVEVO'
        }

    ];
	var currentArtist = 0;
	var totalArtists = dataArray.length;
	$('.js-total-artists').text(totalArtists);
	$('.js-current-artist').text(dataArray[currentArtist].index);
	$('.js-popup-container').children().hide().end().find('#'+dataArray[currentArtist].popup).show();
	
	if (dataArray[currentArtist].youtube) $('#_js-youtube-link').attr('href', dataArray[currentArtist].youtube).parent().show();
	else $('#_js-youtube-link').parent().hide();
	if (dataArray[currentArtist].itunes) $('#_js-itunes-link').attr('href', dataArray[currentArtist].itunes).parent().show();
	else $('#_js-itunes-link').parent().hide();
	if (dataArray[currentArtist].spotify) $('#_js-spotify-link').attr('href', dataArray[currentArtist].spotify).parent().show();
	else $('#_js-spotify-link').parent().hide();
	
	window.sliderTimer = false;
	
	var images = [];
	/* прелоад картинок */
	for (var i in dataArray) {
		var img = new Image();
		img.src = dataArray[i].image;
		images.push(img);
	}
	
	$('.js-artist-left').on('click',function () {
		if (blockBgMove) return false;
		stopSliderTimer();
		blockBgMove = true;
		
		currentArtist--;
		if (currentArtist < 0) currentArtist = totalArtists-1;
		
		moveArtist();
	});
	
	function moveArtist() {
		$('.mm_section-background__image img').addClass('state-old').removeAttr('style');
		$('.mm_section-background__image').append('<img src="'+dataArray[currentArtist].image+'" class="state-new" />');

		//$('#_js-youtube-link').attr('href', dataArray[currentArtist].youtube);

        if (dataArray[currentArtist].youtube) $('#_js-youtube-link').attr('href', dataArray[currentArtist].youtube).parent().show();
		else $('#_js-youtube-link').parent().hide();
		if (dataArray[currentArtist].itunes) $('#_js-itunes-link').attr('href', dataArray[currentArtist].itunes).parent().show();
		else $('#_js-itunes-link').parent().hide();
		if (dataArray[currentArtist].spotify) $('#_js-spotify-link').attr('href', dataArray[currentArtist].spotify).parent().show();
		else $('#_js-spotify-link').parent().hide();

		setTimeout(function () {
			$('.mm_section-background__image img').addClass('status-move-left');
			$('.js-slider-header').addClass('slide-out');
		}, 40);
		
		setTimeout(function () {
			var html = dataArray[currentArtist].name.split(' ').map(function (item) { return '<span>'+item+'</span>'; });
			$('.js-slider-header').removeClass('slide-out').html( html.join(' ') );
		}, 550);
		
		setTimeout(function () {
			$('.mm_section-background__image img.state-old').remove();
			$('.mm_section-background__image img').removeClass();

			$('.js-total-artists').text(totalArtists);
			//$('.js-current-artist').text(dataArray[currentArtist].index);

            setTimeout(function () {
                $('.mm_slider-controls__counter-no._current').addClass('slide-out');
            }, 40);

            setTimeout(function () {
                $('.mm_slider-controls__counter-no._current').removeClass('slide-out');
                $('.js-current-artist').text(dataArray[currentArtist].index);
            }, 100);
	
			blockBgMove = false;
		}, 1200);
		
		$('.js-popup-container').children().hide().end().find('#'+dataArray[currentArtist].popup).show();
	}
	
	$('.js-artist-right').on('click',function () {
		if (blockBgMove) return false;
		stopSliderTimer();
		blockBgMove = true;
		
		currentArtist++;
		if (currentArtist > totalArtists-1) currentArtist = 0;
		
		moveArtist();
		
		/* $('.mm_section-background__image img').addClass('state-old').removeAttr('style');
		$('.mm_section-background__image').append('<img src="'+dataArray[currentArtist].image+'" class="state-new-right" />');
		
		setTimeout(function () {
			$('.mm_section-background__image img').addClass('status-move-right');
		}, 10);
		
		setTimeout(function () {
			$('.mm_section-background__image img.state-old').remove();
			$('.mm_section-background__image img').removeClass();
			
			$('.js-total-artists').text(totalArtists);
			$('.js-current-artist').text(dataArray[currentArtist].index);
			
			blockBgMove = false;
		}, 1200); */
	});
	
	startSliderTimer();
});

function startSliderTimer() {
	window.sliderTimer = setTimeout(function () { 
		$('.js-artist-right').click(); 
		startSliderTimer();
	}, 5000);
}

function stopSliderTimer() {
	if (window.sliderTimer) clearTimeout(window.sliderTimer);
}