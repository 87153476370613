function mm_fullPage() {
    var fullPage = $('#_js-full-page');

	
	
    fullPage.fullpage({
        sectionSelector: '.mm_section',
        anchors: [
            'artist',
            'about',
			'contacts'
        ],
		afterRender: function () {
			if (document.location.hash == '#contacts') {
				$('.mm_section_about').addClass('block-animation');
				//setTimeout(function () {
					$('.mm_section_about').removeClass('anim-at_bottom').addClass('anim-at_top');
				//}, 4);
				
				setTimeout(function () {
					$('.mm_section_about').removeClass('block-animation');
				}, 4000);
			}
		},
		onLeave: function (index, nextIndex, direction) {
			//console.log('onLeave', index, nextIndex, direction);
			if (nextIndex != 1) {
				stopSliderTimer();
			} else {
				startSliderTimer();
			}
			
			if (direction == 'down') {
				if (index+1 != nextIndex) {
					for (var i = index+1; i<nextIndex; i++) {
						$('.mm_section').eq(i-1).addClass('block-animation').removeClass('anim-at_bottom').addClass('anim-at_top');
						$('.mm_section').removeClass('block-animation');
					}
				}
				$('.mm_section').eq(index-1).removeClass('anim-at_bottom').addClass('anim-at_top');
				$('.mm_section').eq(nextIndex-1).removeClass('anim-at_top').addClass('anim-at_bottom');

                if ( nextIndex > 1 ) {
                    //stop
                }

			}
			if (direction == 'up') {
				if (index-1 != nextIndex) {
					for (var i = nextIndex+1; i<index; i++) {
						$('.mm_section').eq(i-1).addClass('block-animation').removeClass('anim-at_top').addClass('anim-at_bottom');
						$('.mm_section').removeClass('block-animation');
					}
				}
				$('.mm_section').eq(nextIndex-1).removeClass('anim-at_bottom').addClass('anim-at_top');
				$('.mm_section').eq(index-1).removeClass('anim-at_top').addClass('anim-at_bottom');

                if ( nextIndex < 2 ) {
                    //start
                }

			}
		}
    });
}