var preloader       = document.getElementById('_preloader-wrapper');
var websiteWrapper  = document.getElementById('_website-wrapper');
var preloaderLine   = document.getElementById('_loading-line');
var more = 0;
var bar_length = 280;
var wait = 7000;
var doneyet = 0;
function progress_bar()
{

    /* the following document element retreives the number of
    images on the HTML document */
    var image_count = document.getElementsByTagName("img").length;

    /* the following variable gets an array of all the images
    in the document */
    var image_array = document.getElementsByTagName("img");

    /* Each part of the progress bar will be bar_length divided by
    image_count rounded. For example: If there are 5 images and
    the total bar_length is 300 then each bar_part will be 60 */
    var bar_part = Math.round(bar_length / image_count);

    /* To display the correct percentage, bar_perc is 100 divided
    by the number of images on the page rounded */
    var bar_perc = Math.round(100 / image_count);

    var new_width = 0; // Will become new width of progress bar
    var j = 0;  // count how many images are complete
    var percent = 0; // Add up the percentage

    for (var i = 0; i < image_count; i++)
    {
        /* The javascript variable 'complete' when used on an
        image element retrieves whether an image is done
        loading or not.  It returns true or flase */
        if (image_array[i].complete)
        {
            percent = percent + bar_perc;
            new_width = new_width + bar_part;
            j++;
        }
    }

    // If the new_width is not growing because an image is still
    // loading then we want to make the bar go up 1% every 1 second
    // as long as it has not reached the next bar_part
    if (new_width <= parseFloat(preloaderLine.style.width)
        && new_width < (j*bar_part + bar_part))
    {
        more = more + .50;
        new_width = new_width + Math.round(more);
        percent = percent + Math.round(more);
    }
    else
        more = 0;  // reset more if we loaded next image

    // The is so the percentage can never go past 100
    if (percent > 100) { percent = 100; } // Bug fix from Martijn189 on 4/13/10

    // Write the new percent in the progress bar window
    //document.getElementById('percent').innerHTML = percent + '%';
    // Make the width of the bar wider so that it matches the percent
    preloaderLine.style.width = new_width + 'px';

    checkstate(); // need for safari
    //document.getElementById('bar').innerHTML = document.readyState;

    /* If all the images have not loaded then call this
    function again in 200ms.  There must be at least one
    image in the document or the progress bar window
    never closes */
    if ( (j < image_count || j == 0 || doneyet == 0) ) {
        setTimeout('progress_bar();', 200);
    }
    else {
        setTimeout(function(){
            preloader.classList.add("_loaded");
            websiteWrapper.removeAttribute("style");
        },500);
    } // if done then close the progress bar pop-up window

        //setTimeout('close_bar();', 500);
} // end function progress_bar()
function checkstate()
{
    // Besides IE, Safari also can use document.readyState
    // but Safari does not support onreadystatechange, so
    // we will keep calling this function with progress_bar().

    // Check to see if document is not "complete" but
    // is loaded enough to be "interactive"
    if (document.readyState=="complete" ||
        document.readyState=="complete")
        doneyet = 1;

} // end function checkstate()
function saydone()
{
    doneyet = 1;
}  // end function saydone()

// for other browsers that don't have DOM complete variables
setTimeout('saydone();', wait);