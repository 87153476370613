function mm_popup() {

    var show    = $('._js-show-popup'),
        hide    = $('._js-hide-popup'),
        wrapper = $('.mm_website-wrapper');

    show.on('click', function () {
        if (blockBgMove) return false;
        blockBgMove = true;
		stopSliderTimer();
		
        if (!$(this).hasClass('_active')) {
            $(this).addClass('_active');
            wrapper.addClass('_popup-shown');
            //disable vertical scrolling
            $.fn.fullpage.setAllowScrolling(false);
        } else {
            $(this).removeClass('_active');
            wrapper.removeClass('_popup-shown');
            //enable vertical scrolling
            $.fn.fullpage.setAllowScrolling(true);
        }
        setTimeout(function () { blockBgMove = false; }, 1500);
    });
    hide.on('click', function () {
        if (blockBgMove) return false;
        blockBgMove = true;
		
        show.removeClass('_active');
        wrapper.removeClass('_popup-shown');
        //enable vertical scrolling
        $.fn.fullpage.setAllowScrolling(true);
        setTimeout(function () { blockBgMove = false; }, 1500);
		startSliderTimer();
    });

}