function move(amplitude, frequency, length, i, height) {
    var points = []; //[0,amplitude*2];
    var width = length;
    var x = 0,
        y;
    while (x++ <= width) {
        y = Math.sin(x * frequency + i);
        points.push([x, height + y * amplitude / 2].join(' '));
    }
    //points.push([length,amplitude*2].join(' '))
    //points.push([0,amplitude*2].join(' '));
    return points;
}
var svg = document.getElementById('_js-volume-svg');
var line = svg.querySelector('polyline');
var counter = 0;
var width = svg.getAttribute('width');
var height = svg.getAttribute('height') / 2;
var currentHeight = height;
var decay = false;
function mm_draw() {
    var c = ++counter / 20;
    if (decay && currentHeight > 0) currentHeight -= 0.25;
    if (!decay && currentHeight < height) currentHeight += 0.25;
    line.setAttribute('points', move(currentHeight, 0.48, width, c, height));

    requestAnimationFrame(mm_draw);
}

function toggledecay() {
    decay = !decay;
}