var blockBgMove = false;
function backgroundMove() {

    var center = [document.body.clientWidth / 2, document.body.clientHeight / 2];
    var timer;
    $('body').on('mousemove', function (e) {
		if (blockBgMove) return false;
        //if (timer) clearTimeout(timer);

        // timer = setTimeout(function () {

        $('.mm_section-background__item._1 .mm_section-background__image img').css({ 'transform': 'translateX(-' + (50 - (e.originalEvent.clientX - center[0]) / center[0] * 0.01 * 100) + '%) translateY(-' + (50 - (e.originalEvent.clientY - center[1]) / center[1] * 0.01 * 100) + '%)' }); //, 300);
        $('.mm_section-background__item._2 .mm_section-background__image img').css({ 'transform': 'translateX(-' + (50 - (e.originalEvent.clientX - center[0]) / center[0] * 0.01732 * 100) + '%) translateY(-' + (50 - (e.originalEvent.clientY - center[1]) / center[1] * 0.01732 * 100) + '%)' }); //, 300);, 300);
        $('.mm_section-background__item._3 .mm_section-background__image img').css({ 'transform': 'translateX(-' + (50 - (e.originalEvent.clientX - center[0]) / center[0] * 0.03 * 100) + '%) translateY(-' + (50 - (e.originalEvent.clientY - center[1]) / center[1] * 0.03 * 100) + '%)' }); //, 300);, 300);
        //}, 200);
    });
}