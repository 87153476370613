function mm_navigation() {

    var burger      = $('#_nav-burger'),
        navigation  = $('#_navigation'),
        navLink     = $('._js-nav-link');

    burger.on('click', function(){

        if ( !$(this).hasClass('_active') ) {
            $(this).addClass('_active');
            navigation.addClass('_expanded');
            //disable vertical scrolling
            $.fn.fullpage.setAllowScrolling(false);
        } else {
            $(this).removeClass('_active');
            navigation.removeClass('_expanded');
            //enable vertical scrolling
            $.fn.fullpage.setAllowScrolling(true);
        }

    });

    navLink.on('click', function(){
        // navLink.removeClass('_active');
        /*if ( !$(this).hasClass('_active') ) {
            $(this).addClass('_active');

        }*/
        burger.removeClass('_active');
        navigation.removeClass('_expanded');
        //enable vertical scrolling
        $.fn.fullpage.setAllowScrolling(true);
    });

}