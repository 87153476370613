function mm_sound() {
    var sound	        = document.getElementById('_main-audio'),
        volumeControl 	= $('._js-sound_control');

    volumeControl.on('click', function(){
        if ( !$(this).hasClass('_on') ) {
            $(this).addClass('_on').removeClass('_off');
            mm_enableVolume();
        } else {
            $(this).removeClass('_on').addClass('_off');
            mm_disableVolume();
        }
    });

    if (sound) {
        $(document).ready(function () {
            sound.volume = 1;
            if (!sound.paused) $('._js-sound_control').addClass('_on');
			else {
				$('._js-sound_control').addClass('_off');
				toggledecay();
			}
        });
		mm_draw();
    }
    function mm_enableVolume() {
        sound.muted = false;
        sound.volume = 1;
    }
    function mm_disableVolume() {
        sound.volume = 0;
        sound.muted = true;
    }
}

