function mm_autoPlay() {
   /* var autoPlayInterval = null,
        stopTrigger      = $('._js-show-popup'),
        startTrigger     = $('._js-hide-popup'),
        arrows           = $('.js-artist-left, .js-artist-right'),
        timer            = 4000;

    function autoPlay() {
        $('.js-artist-right').trigger('click');
    }
    stopTrigger.on('click', function(){
        clearInterval(autoPlayInterval);
    });
    startTrigger.on('click', function(){
        autoPlayInterval = setInterval(autoPlay, timer);
    });

    autoPlayInterval = setInterval(autoPlay, timer);*/
}

var time;

function autoPlay() {
    $('.js-artist-right').trigger('click');

    time = setTimeout(function (){
        $('.js-artist-right').trigger('click');
    });
}
clearTimeout(time);


time = setTimeout(function (){
    $('.js-artist-right').trigger('click');
});


