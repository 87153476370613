var bar_length = 300;
$(function() {
	console.log(`iBrush HTML Starter`);
	console.log(APP);

    mm_fullPage();
    backgroundMove();
    mm_sound();
    //mm_draw();
    mm_navigation();
    mm_popup();

    $('.js-slider-header').on('mouseenter mouseleave', function(){
        $(this).addClass('_slide-out-hover').delay(300).queue(function(next){
            $(this).removeClass('_slide-out-hover');
            next();
        });
    });

});

$(window).on('load', function(){

    progress_bar();

});